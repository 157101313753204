@font-face {
    font-family: BebasNeue;
    src: url("./fonts/BebasNeue-Regular.ttf");
}

@media only screen and (max-width: 900px) {
    :root {
        font-size: 13px;
    }
}

body {
    width: 100vw;
    overflow-x: hidden;
    margin: 0;
    font-size: 16px;
    font-family: BebasNeue, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
}
